$hyoo_board $mol_drop
	allow / \move
	groups_all / \
	Sub <= Page $mol_page
		title \$hyoo_board
		plugins /
			<= Theme $mol_theme_auto
		Head null
		body /
			<= Groups $mol_view
				sub <= groups /
					<= Group*id $hyoo_board_group
						title? <=> group_title*? \
						links? <=> group_links*? /
						delete? <=> group_delete*? null
						link_adopt? <=> link_adopt? null
						link_outer_receive? <=> group_add*? null
		foot /
			<= Hint $mol_hint dictionary *
				rename @ \Hold Alt to rename
				group @ \Drop to group name to make new
				image @ \Add image url to the end of name
			<= Source $mol_link_source uri \https://github.com/hyoo-ru/board.hyoo.ru
			<= Lights $mol_lights_toggle
